import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import HeaderWithTaglineOption from "../../../components/AcfPageBlocks/HeaderWithTaglineOption"
import FilteredProductsList from "../FilteredProductsList"
import FilterMobileMenu from "../FilterMobileMenu"
import CategoryPageAcfComponents from "./CategoryPageAcfComponents"
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb"
import ProductCategoryHeader from "./ProductCategoryHeader"

const CATEGORY_PAGE_QUERY = graphql`
  query GET_CATEGORY_PAGE_CENTRALBIKES {
    cats: allWpProductCategory {
      nodes {
        name
        ...CategoryPageCategoryQueryCB
      }
    }
    categoryPageDefaultPosts: allWpPost(limit: 3) {
      nodes {
        ...PostDataQuery
      }
    }
    helpCtaImg1: file(name: { eq: "category-page-default-help-img1" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
    helpCtaImg2: file(name: { eq: "category-page-default-help-img2" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
  }
`

const ProductCategoryView = ({
  location,
  products,
  data,
  pageContext,
  breadcrumbData,
  Filters,
  mobileToggle,
  toggleMenu,
  handleClearFilters,
  handlePageChange,
  handleSelectSort,
  CustomToggle,
  getDynamicProductLink,
}) => {
  const { categoryPath, id, slugs } = pageContext

  const acfData = useStaticQuery(CATEGORY_PAGE_QUERY)
  let defaultOtherCategories = []
  let pageData = {}

  const pageCategory = acfData.cats.nodes
    .map(category => {
      if (category.databaseId !== id) {
        defaultOtherCategories.push(category)
        return null
      }
      return category
    })
    .filter(cat => cat !== null)

  if (pageCategory.length && pageCategory[0].extendedCategoryData) {
    const {
      extendedCategoryData: { label, mainDescription, largeImage, featureList },
      productCategoryPageLayouts: { categoryLayoutsData },
    } = pageCategory[0] || {}

    const tags = featureList?.map(item => {
      return { tag: item.listItem }
    })

    pageData = {
      id,
      label,
      mainDescription,
      subTitle: categoryLayoutsData.header.subTitle,
      subDescription: categoryLayoutsData.header.subDescription,
      largeImage,
      categoryLayoutsData,
      tags,
    }
  }
  const isHeaderWithTaglineOptionToBeDisplayed1Displayed = (typeof pageData !== "undefined"
      && pageData.label !== null
      && pageData.mainDescription !== null
  )

  return (
    <>
      {mobileToggle && (
        <FilterMobileMenu
          Filters={Filters}
          toggleMenu={toggleMenu}
          handleClearFilters={handleClearFilters}
        />
      )}
      <Breadcrumb data={breadcrumbData} />

      <ProductCategoryHeader headerData={pageCategory[0].productCategoryHeader} categoryPath={categoryPath} />

      {isHeaderWithTaglineOptionToBeDisplayed1Displayed ? (
        <HeaderWithTaglineOption
          title={pageData.label}
          description={pageData.mainDescription}
          subTitle={pageData.subTitle}
          subDescription={pageData.subDescription}
          image={pageData.largeImage}
          tags={pageData.tags}
        />
      ) : (
        <h1 className="my-5 text-uppercase text-center">{pageContext.name}</h1>
      )}

      <FilteredProductsList
        location={location}
        data={data}
        breadcrumbData={breadcrumbData}
        pageContext={pageContext}
        products={products}
        Filters={Filters}
        toggleMenu={toggleMenu}
        CustomToggle={CustomToggle}
        handleSelectSort={handleSelectSort}
        handleClearFilters={handleClearFilters}
        handlePageChange={handlePageChange}
        getDynamicProductLink={getDynamicProductLink}
        slugs={slugs}
      />

      {pageData && (
        <CategoryPageAcfComponents
          location={location}
          data={{
            pageData,
            defaultOtherCategories,
            posts: acfData.categoryPageDefaultPosts,
            helpCtaImg1: acfData.helpCtaImg1,
            helpCtaImg2: acfData.helpCtaImg2,
          }}
        />
      )}
    </>
  )
}

export default ProductCategoryView
