import React from "react"

import { DEFAULT_PADDING, DEFAULT_END_SLIDER_PADDING } from "../../../utils/acfComponentHelper"

import TextPanelWithTwoBkgdImgs from "../../../components/AcfPageBlocks/TextPanelWithTwoBkgdImgs"
import Faq from "../../../components/AcfPageBlocks/Faq"
import ThreePostsRow from "../../../components/AcfPageBlocks/ThreePostsRow"
import CategoriesCarousel from "../../../components/AcfPageBlocks/CategoriesCarousel"

const CategoryPageAcfComponents = ({ data, location }) => {
  const {
    pageData: {
      label,
        categoryLayoutsData: {
          helpCallToAction,
          faq,
          postRecommendations,
          moreCategoriesSlider,
        }
    },
    defaultOtherCategories,
    posts,
    helpCtaImg1,
    helpCtaImg2,
  } = data

  const defaultHelpCtaImgs = [
    {
      image: { altText: `${label} background image 1`, localFile: helpCtaImg1 },
    },
    {
      image: { altText: `${label} background image 2`, localFile: helpCtaImg2 },
    },
  ]

  return (
    <>
     {helpCallToAction.button && <TextPanelWithTwoBkgdImgs
        imageBoxes={helpCallToAction.imageBoxes || defaultHelpCtaImgs}
        sectionHeading={helpCallToAction.sectionHeading}
        text={helpCallToAction.text}
        button={helpCallToAction.button}
        config={DEFAULT_PADDING}
      />}

      {faq.accordion && faq.accordion.length && <Faq
        sectionHeading={faq.sectionHeading}
        description={faq.description}
        accordion={faq.accordion}
        config={DEFAULT_PADDING}
      />}

      <ThreePostsRow
        sectionHeading={postRecommendations.sectionHeading}
        defaultPosts={posts.nodes}
        selectedPosts={postRecommendations.selectedPosts}
        config={DEFAULT_PADDING}
      />

      <CategoriesCarousel
        location={location}
        sectionHeading={moreCategoriesSlider.sectionHeading}
        text={moreCategoriesSlider.text}
        categories={moreCategoriesSlider.categories || defaultOtherCategories}
        config={DEFAULT_END_SLIDER_PADDING}
      />
    </>
  )
}

export default CategoryPageAcfComponents
